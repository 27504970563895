import axios from '@/libs/axios'
import helpers from '@/libs/helper'

import appActions from '@/store/intragile/actions/appActions'
import authActions from '@/store/intragile/actions/authActions'
import userActions from '@/store/intragile/actions/userActions'
import logActions from '@/store/intragile/actions/logActions'
import categoryActions from '@/store/intragile/actions/categoryActions'
import tagActions from '@/store/intragile/actions/tagActions'
import postActions from '@/store/intragile/actions/postActions'
import entityActions from '@/store/intragile/actions/entityActions'
import store from '@/store'
import fileActions from "@/store/intragile/actions/fileActions";

// setup axios
axios.interceptors.request.use(
    config => {
        const accessToken = store.state.token
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        if (process.env.NODE_ENV === 'development') console.log('Interceptor request[', config.method, '+', config.url, ']', config);
        return config
    },
    error => {
        // Do something with request error
        helpers.showErrorToast('', 'Ismeretlen hiba! Keresse fel az oldal üzemeltetőjét')
        if (process.env.NODE_ENV === 'development') console.error('Interceptor request error: ', error);
        return Promise.reject(error)
    },
)

axios.interceptors.response.use(
    //If we have a response from our recent http call
    (response) => {
        if (process.env.NODE_ENV === 'development') console.log('Interceptor response[', response.config.method, '+', response.config.url, ']', response);
        return response;
    },
    (error) => {
        if (error.response.status === 401) {
            helpers.showErrorToast('Lejárt munkamenet!')
            helpers.logout()
            // eslint-disable-next-line no-underscore-dangle
        }
        //Catches 403 error from our axios request
        if (process.env.NODE_ENV === 'development') console.error('Interceptor response error: ', error);
        return Promise.reject(error);
    }
);

const actions = {
    ...appActions,
    ...authActions,
    ...userActions,
    ...categoryActions,
    ...tagActions,
    ...postActions,
    ...fileActions,
    ...entityActions,
    ...logActions,
}

export default actions
