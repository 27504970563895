import axios from '@/libs/axios'

const postActions = {
    fetchPosts({commit, dispatch}, searchParams) {
        return axios.post('/blog/posts/search', {params: searchParams})
    },
    fetchPostsForSelect({commit, dispatch}, searchParams) {
        return new Promise((resolve, reject) => {
            axios.get('/blog/posts/list', {params: {page: 1, limit: 10000, language: searchParams.language}})
                .then(response => {
                    const list = []

                    if (response.data.entities.data.length) {
                        for (const key in response.data.entities.data) {
                            list.push({
                                value: parseInt(response.data.entities.data[key].id),
                                title: response.data.entities.data[key].title + ' (ID: ' + response.data.entities.data[key].id + ')'
                            })
                        }
                    }

                    resolve(list)
                }).catch(error => {
                reject(error)
            })
        })
    },
    fetchPost({commit, dispatch}, id) {
        return axios.get(`/blog/posts/${id}`)
    },
    storePost({commit, dispatch}, data) {
        return axios.post('/blog/posts', data)
    },
    updatePost({commit, dispatch}, data) {
        return axios.put(`/blog/posts/${data.id}`, data)
    },
    deletePost({commit, dispatch}, id) {
        return axios.delete(`/blog/posts/${id}`)
    },
    fetchPostStatuses({}) {
        return axios.get('/blog/posts/statuses')
    },
    fetchPostTranslations({commit, dispatch}, id) {
        return axios.get(`/blog/posts/${id}/translations`)
    },
    updatePostVideos({ commit, dispatch }, data) {
        return axios.post(`/blog/posts/${data.id}/update-videos`, data)
    },
    addRelatedPost({ commit, dispatch }, data) {
        return axios.post(`/blog/posts/${data.id}/related/${data.relatedId}`)
    },
    deleteRelatedPost({ commit, dispatch }, data) {
        return axios.delete(`/blog/posts/${data.id}/related/${data.relatedId}`)
    },
    updateRelatedPost({ commit, dispatch }, data) {
        return axios.post(`/blog/posts/${data.id}/update-related-posts`, data)
    },
}

export default postActions
